import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';

import {ref, set} from "firebase/database"
import { database } from '../../data/firebase';
import { uid } from 'uid';

import {NotificationManager} from 'react-notifications';

import './style.css';

export const EscolhaDeNome = () => {

    const [nome, setNome] = useState('');
    const navigate = useNavigate();

    const SaveName = (e) =>{
        setNome(e.target.value);
        localStorage.setItem('nome', e.target.value)
    }

    const Iniciar = () => {

        let idPartida = localStorage.getItem('idPartida');
        let idJogador = uid();

        if(localStorage.getItem('idJogador')){
            idJogador = localStorage.getItem('idJogador')
        }

        const body = {
            nome: nome,
            time: 'A'
        }

        set(ref(database, `/partidas/${idPartida}/jogadores/${idJogador}`), body)
        .then(() => {
            localStorage.setItem('idJogador', idJogador)
            NotificationManager.success('Participante incluso com sucesso', 'Pronto');
            navigate('/objetivogeral')
            // window.location.href = '/objetivogeral'
        })
        .catch((erro) => {
            NotificationManager.error(erro, 'Click me!', 5000, () => {
                alert('callback');
            });
        })
    }

    useEffect(() => {

    },[])

    return (
        <>
            <div className="centered">
                <h1 data-text="Escreva seu Nome">
                    <span>Escreva seu Nome</span>
                </h1>
                <div className='glass card'>
                    <input 
                        type="text" 
                        value={nome} 
                        onChange={(e) => SaveName(e)} 
                        className="glass input_glass escolhanome"
                        placeholder='Digite seu nome aqui...' 
                    />                    
                    {nome && <button onClick={() => Iniciar()} className='button'>Continuar</button>}
                </div>
            </div>
        </>
    );
}

export default EscolhaDeNome;