import React, {useEffect, useState, } from 'react';
import {useNavigate} from 'react-router-dom';

import {ref, update ,onValue} from "firebase/database"
import { SendData, database } from '../../../data/firebase';

import './style.css'
import AdminMenu from '../../../components/Admin/Menu';

export const Partida = () => {
    
    let idPartida = localStorage.getItem('idPartida');

    const [jogadores, setJogadores] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
          
        let userAdmin = localStorage.getItem('admin');
        if(userAdmin == null){
            navigate("../../admin/login")
        }
        
        setInterval(() => {
            ListarJogadores();
        }, 3000);

        VerificarPartidaDisponivel()
    },[]);
    
    const CriarPartida = () => {

        let partida = {
            dataInicio : Date.now(),
            questaoAtual : 0
        }

        SendData('partidas', partida)
        VerificarPartidaDisponivel();

        navigate('/admin/partida')
    }

    const IniciarPartida = () => {

        if(jogadores.length >= 1){
            navigate('/objetivogeral')
            // window.location.href = '/objetivogeral'
        }
    }

    // Verificar se tem partida disponível para aparecer o botão "Iniciar"
    const VerificarPartidaDisponivel = () => {
        const dbRef = ref(database, '/partidas');
        onValue(dbRef, (snapshot) => {
            
            snapshot.forEach(childSnapshot => {

                let keyName = childSnapshot.key;
                let data = childSnapshot.val();
                
                if(!data.dataFinal){                    
                    localStorage.setItem('idPartida', keyName)
                }
            })            
        })
    }


    const ListarJogadores = () =>{
        const dbRef = ref(database, `/partidas/${idPartida}/jogadores`);
        onValue(dbRef, (snapshot) => {
            let records = []
            snapshot.forEach(childSnapshot => {
                let keyName = childSnapshot.key;
                let data = childSnapshot.val();
                records.push({"key": keyName, "data": data})
            })
            setJogadores(records)
        })
    }

    const FinalizarPartida = () =>{
        if(confirm('Deseja realmente finalizar esta partida?'))
        {
            update(ref(database, `/partidas/${idPartida}`), {dataFinal: Date.now(), paginaAtual:"/jogar"})
            localStorage.removeItem('idPartida')
            localStorage.removeItem('totalQuestoes')
            navigate('/admin/partida')
            // window.location.href = '/admin/partida'
        }
    }

    return (
        <div className="container">
            <div className='card glass'>
                <AdminMenu pagina="partida" />
                <h2>Participantes : </h2>                
                {!idPartida && <button className='button' onClick={() => CriarPartida()}>CRIAR EVENTO</button>}

                <section className='participantes'>
                    {jogadores.map((item, key) => {
                        console.log(item);
                        return(
                            <span key={key}>{key+1}</span>
                        )
                    })}                
                </section>
                
                {idPartida &&
                    <>
                        <button className='button btn_full' onClick={() => IniciarPartida()}>
                            INICIAR EVENTO
                        </button>

                        <button className='button glass center' onClick={() => FinalizarPartida()}>
                            FINALIZAR
                        </button>
                    </>
                }

            </div>     
        </div> 
    );
}

export default Partida;