import React, { useEffect, useState } from 'react';
import { SetPage, VerifyPage, database } from '../../data/firebase';
import { ref, update, onValue } from "firebase/database"
import {useNavigate} from 'react-router-dom';
import './style.css'


export const Pergunta = () => {

    let userAdmin = localStorage.getItem('admin');
    let idPartida = localStorage.getItem('idPartida');
    let idJogador = localStorage.getItem('idJogador');
    let contexto = localStorage.getItem('contextoAtual');

    const navigate = useNavigate();

    let pagina = '/pergunta';

    const Continuar = () => {
        SetPage('/feedback')
    }

    const [pergunta, setPergunta] = useState({
        tempo : 0,
        a : '',
        b : '',
        c : '',
        d : '',
        a_pontos : 0,
        b_pontos : 0,
        c_pontos : 0,
        d_pontos : 0, 
    })

    const [respondido, setRespondido] = useState(false);
    const [escolhido, setREscolhido] = useState({});
    const [confirmado, setConfirmado] = useState(false);



    const GetSituacao = () => {

        let idPatida = localStorage.getItem('idPartida')
        let questaoAtual = localStorage.getItem('questaoAtual')

        const dbRef = ref(database, `/partidas/${idPatida}`);
        onValue(dbRef, (snapshot) => {
            let data = snapshot.val();
            if(data.questaoAtual === 0){
                update(ref(database, `/partidas/${idPatida}`), {questaoAtual: 1})
            }
            localStorage.setItem('questaoAtual', data.questaoAtual)
        })

        const dbRefQuestoes = ref(database, '/questoes');

        if(questaoAtual == null){
            questaoAtual = 1;
        }

        onValue(dbRefQuestoes, (snapshot) => {
            snapshot.forEach(childSnapshot => {
                let data = childSnapshot.val();

                if(parseInt(data.ordem) === parseInt(questaoAtual)){                    
                    setPergunta({
                        tempo : data.tempo,
                        a : data.alternativa_a_texto,
                        b : data.alternativa_b_texto,
                        c : data.alternativa_c_texto,
                        d : data.alternativa_d_texto,
                        a_pontos : data.alternativa_a_pontos,
                        b_pontos : data.alternativa_b_pontos,
                        c_pontos : data.alternativa_c_pontos,
                        d_pontos : data.alternativa_d_pontos,                        
                    })
                    StartCountDown(data.tempo);
                }
            })
        })
    }

    const StartCountDown = (tempo) => {

        let interval = tempo;
        let progressBar = document.querySelector('.bar_fill');
    
        var countDown = setInterval(() => {
            interval--;
            let progressWidth = interval / 10 * 10;
    
            if(interval > 0) {
                progressBar.style.width = progressWidth + "%"
                progressBar.style.transition = "width 1s ease-in-out"
            }else{
                clearInterval(countDown);
                progressBar.style.width = "0%";

                // Esolher alternativa aleatoriamente
                let alternativas = ['a', 'b', 'c', 'd'];
                let random = Math.floor(Math.random() * 4) + 1;
                
                if(!localStorage.getItem('respondido')){
                    !userAdmin && document.querySelector(`#tool-${random}`).setAttribute(`checked`, true)
                    !userAdmin && Escolher(alternativas[random-1]);
                }             

                if(userAdmin){
                    let btn_continuar = document.querySelector('.btn_continuar');
                    btn_continuar.click();
                }

            }
        }, 1000);
    }


    const Escolher = (alternativa) =>{
        
        let questaoAtual = localStorage.getItem('questaoAtual')     
        let resposta = {};

        switch (alternativa) {
            case 'a':
                resposta = {
                    questao: questaoAtual,
                    resposta: 'a',
                    pontos: pergunta.a_pontos,
                    alternativa: pergunta.a
                }
                break;
            case 'b':
                resposta = {
                    questao: questaoAtual,
                    resposta: 'b',
                    pontos: pergunta.b_pontos,
                    alternativa: pergunta.b
                }
                break;
            case 'c':
                resposta = {
                    questao: questaoAtual,
                    resposta: 'c',
                    pontos: pergunta.c_pontos,
                    alternativa: pergunta.c
                }
                break;
            case 'd':
                resposta = {
                    questao: questaoAtual,
                    resposta: 'd',
                    pontos: pergunta.d_pontos,
                    alternativa: pergunta.d
                }
                break;
        
            default:
                resposta = {
                    questao: questaoAtual,
                    resposta: '',
                    pontos: 0
                }
                break;
        }
        setREscolhido(resposta)
        setRespondido(true)
    }

    const ConfirmarResposta = () => {
        let questaoAtual = localStorage.getItem('questaoAtual')

        if(!userAdmin && !localStorage.getItem('respondido')){
            localStorage.setItem('respondido', true);
            update(ref(database, `/partidas/${idPartida}/jogadores/${idJogador}/questoes/${questaoAtual}`), escolhido)
        }
        setConfirmado(true)
    }

    useEffect(()=>{
        VerifyPage(pagina, navigate)
        GetSituacao();        
    },[])

    return (
        <>
            <div className='container'>
                
                <div className='card glass'>
                    {!confirmado &&
                        <>
                            <h2>Qual seria a ação esperada? </h2>                    
                            <strong>{contexto && `Situação : `}</strong>
                            {contexto && <div dangerouslySetInnerHTML={{__html: contexto}} />}<br /><br />

                            <div className='bar'>
                                <div className='bar_fill'></div>
                            </div>
                            <div className='alternativas'>
                                <input className="checkbox-tools" type="radio" name="tools" id="tool-1" />
                                <label className="for-checkbox-tools" htmlFor="tool-1" onClick={() => Escolher('a')}>
                                    <i className='uil uil-line-alt'></i>
                                    A - {pergunta && pergunta.a}
                                </label>

                                <input className="checkbox-tools" type="radio" name="tools" id="tool-2" />
                                <label className="for-checkbox-tools" htmlFor="tool-2" onClick={() => Escolher('b')}>
                                    <i className='uil uil-line-alt'></i>
                                    B - {pergunta && pergunta.b}
                                </label>

                                <input className="checkbox-tools" type="radio" name="tools" id="tool-3" />
                                <label className="for-checkbox-tools" htmlFor="tool-3" onClick={() => Escolher('c')}>
                                    <i className='uil uil-line-alt'></i>
                                    C - {pergunta && pergunta.c}
                                </label>

                                <input className="checkbox-tools" type="radio" name="tools" id="tool-4" />
                                <label className="for-checkbox-tools" htmlFor="tool-4" onClick={() => Escolher('d')}>
                                    <i className='uil uil-line-alt'></i>
                                    D - {pergunta && pergunta.d}
                                </label>
                            </div>

                            {!userAdmin && respondido && <button onClick={() => ConfirmarResposta()} className='button'>Enviar</button>}
                            {userAdmin && <button onClick={() => Continuar()} className='button btn_continuar'>Continuar</button>}
                        </>
                    }

                    {confirmado && <h2>Decisão enviada! ► {escolhido?.resposta}</h2>}
                    {confirmado && <h3>{escolhido?.alternativa}</h3>}
                    
                </div>
            </div>
        </>
    );
}

export default Pergunta;