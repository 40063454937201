import React, { useState, useEffect } from 'react';
import bgAudio from '../../assets/audio/background/NikiN - Cyber Warfare Bass Loop 1.wav';
import './style.css';

const BackgroundAudio = () => {
  const [volume, setVolume] = useState(0); // Valor inicial do volume
  const audio = new Audio(bgAudio);
  let userAdmin = localStorage.getItem('admin');

  useEffect(() => {
    if(userAdmin != null){
      audio.loop = true;
      audio.volume = volume;
      audio.play();
  
      return () => {
        audio.pause();
        audio.currentTime = 0;
      };
    }
  }, [volume]);

  useEffect(() =>{
    
  },[])

  return (
    <div className="background-audio">

      
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={(e) => setVolume(parseFloat(e.target.value))}
          className="volume-slider"
        />
      

    </div>
  );
};

export default BackgroundAudio;