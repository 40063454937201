import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter } from "react-router-dom";

import './index.css';

import Jogar    from './pages/Jogar';
import Situacao from './pages/Situacao';
import Pergunta from './pages/Pergunta';
import Feedback from './pages/Feedback';
import EscolhaDeTime from './pages/EscolhaDeTime';
import EscolhaDeNome from './pages/EscolhaDeNome';
import ObjetivoGeral from './pages/ObjetivoGeral';
import Resultados from './pages/Resultados';

import Questoes from './pages/Admin/Questoes';
import Partida from './pages/Admin/Partida';
import Login from './pages/Admin/Login';
import {history} from "./history";

import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';

import Video from './components/Video';
import BackgroundAudio from './components/BackgroundAudio';

const Rotas = () => {

  return(
      <BrowserRouter history={history}>
          <Routes>
            <Route element = { <Jogar /> }          path={"/"} exact />
            <Route element = { <Jogar /> }          path={"/jogar"} />
            <Route element = { <EscolhaDeTime /> }  path={"/escolhadetime"} />
            <Route element = { <EscolhaDeNome /> }  path={"/escolhadenome"} />
            <Route element = { <Situacao /> }       path={"/situacao"} />
            <Route element = { <Pergunta /> }       path={"/pergunta"} />
            <Route element = { <Feedback /> }       path={"/feedback"} />
            <Route element = { <ObjetivoGeral /> }  path={"/objetivogeral"} />
            <Route element = { <Resultados /> }     path={"/resultados"} />

            <Route element = { <Login /> }          path={"/admin/"} />
            <Route element = { <Login /> }          path={"/admin/login"} />
            <Route element = { <Questoes /> }       path={"/admin/questoes"} />
            <Route element = { <Partida />  }       path={"/admin/partida"} />

          </Routes>
      </BrowserRouter>
  )
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Video />
    <BackgroundAudio />
    <Rotas />
    <NotificationContainer/>
  </>
);

