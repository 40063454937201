
import { initializeApp } from "firebase/app";
import {getDatabase, set, ref, remove, update, get, child, onValue} from "firebase/database"
import { uid } from 'uid';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

export const firebaseConfig = {
  apiKey: "AIzaSyAhzfYANiuph418czXMtnFIq7vCg7E-jek",
  authDomain: "cisoadvisor-b1e6c.firebaseapp.com",
  databaseURL: "https://cisoadvisor-b1e6c-default-rtdb.firebaseio.com",
  projectId: "cisoadvisor-b1e6c",
  storageBucket: "cisoadvisor-b1e6c.appspot.com",
  messagingSenderId: "98276342012",
  appId: "1:98276342012:web:a2a436c5386b5501bcff79"
};

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app)

export const GetData = (endpoint) => {
    get(child(ref(database), endpoint))
    .then((snapshot) => {
        if(snapshot.exists()){
            return snapshot.val();
        }else{
            console.log('No data');
            return null;
        }
    })
    .catch(erro => { console.log(`Erro : ${erro}`) })
    
}

export const SendData = (endpoint, body) => {    
    set(ref(database, `${endpoint}/${uid()}`), body)
    .then(() => NotificationManager.success('Cadastro realizado com sucesso', 'Pronto'))
    .catch((erro) => {
        NotificationManager.error(erro, 'Click me!', 5000, () => {
            alert('callback');
        });
    })
}

export const UpdateData = (endpoint, body) => {    
    set(ref(database, endpoint), body)
    .then(() => NotificationManager.success('Cadastro alterado com sucesso', 'Pronto'))
    .catch((erro) => {
        NotificationManager.error(erro, 'Click me!', 5000, () => {
            alert('callback');
        });
    })
}

export const DeleteData = (endpoint, id) => {
    remove(ref(database, endpoint+"/"+id))
    .then(() => NotificationManager.success('Deletado com sucesso', 'Pronto'))
    .catch((erro) => {
        NotificationManager.error(erro, 'Click me!', 5000, () => {
            alert('callback');
        });
    })
}


export const VerifyPage = (pagina, navigate) => {

    let idPartida = localStorage.getItem('idPartida')
    const dbRef = ref(database, `/partidas/${idPartida}`);
    onValue(dbRef, (snapshot) => {        
        let data = snapshot.val();
        if(pagina !== data.paginaAtual){
            navigate(data.paginaAtual)
            // window.location.href = data.paginaAtual
        }       
    })
}

export const SetPage = (pagina) => {
    let idPatida = localStorage.getItem('idPartida')
    update(ref(database, `/partidas/${idPatida}`), {paginaAtual: pagina})
}