import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import './style.css';

export const EscolhaDeTime = () => {

    const [contagem, setContagem] = useState('30');
    const [nome] = useState(localStorage.getItem('nome'));
    const navigate = useNavigate();

    const iniciarContagem = (duration) => {
        setInterval(() => {
            duration = duration -1;
            if (duration <= 0) {
                duration = 0;
                navigate('/objetivogeral')
                // window.location.href = '/objetivogeral'
            }
            setContagem(duration);
        }, 1000);
    }

    useEffect(()=>{
        var duration = 31; 
        iniciarContagem(duration);
    },[])


    return (
        <>
            <div className="centered">
                <h2>{nome}</h2>
                <h1 data-text="Escolha seu Time">
                    <span>Escolha seu Time</span>
                </h1>
                <div className='team_select'>
                    <Link to="/objetivogeral" className='button'>Time A</Link>
                    <Link to="/objetivogeral" className='button'>Time B</Link>
                </div>
                <h2 className='contagem' id='timer'>{contagem}</h2>
            </div>        
        </>
    );
}

export default EscolhaDeTime;