import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom'

import { onValue } from 'firebase/database';
import {ref , set} from "firebase/database"
import { database } from '../../data/firebase';
import { uid } from 'uid';

import Footer from '../../components/Footer';


export const Jogar = () => {
    
    const [partida, setPartida] = useState('')
    const navigate = useNavigate();

    const AcessarPainelAdmin = () => {
        navigate("/admin")
    }

    // Verificar se tem partida disponível para aparecer o botão "Iniciar"
    const VerificarPartidaDisponivel = () => {
        
        
        const dbRef = ref(database, '/partidas');
        onValue(dbRef, (snapshot) => {
            
            snapshot.forEach(childSnapshot => {

                let keyName = childSnapshot.key;
                let data = childSnapshot.val();

                if(!data.dataFinal){

                    localStorage.removeItem('idPartida')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('questaoAtual')

                    setPartida(keyName)                    
                    localStorage.setItem('idPartida', keyName)
                }
            })            
        })
    }

    const IniciarDireto = () => {

        let idPartida = localStorage.getItem('idPartida');
        let idJogador = uid();

        if(localStorage.getItem('idJogador')){
            idJogador = localStorage.getItem('idJogador')
        }

        const body = {
            nome: "CISO "+idJogador,
            time: 'A'
        }

        set(ref(database, `/partidas/${idPartida}/jogadores/${idJogador}`), body)
        .then(() => {
            localStorage.setItem('idJogador', idJogador)
            // useNavigate
            navigate('/objetivogeral')
            // window.location.href = '/objetivogeral'
        })
        .catch((erro) => {
            console.log(erro);
        })
    }

    useEffect(() => {
        localStorage.removeItem('idPartida')
        VerificarPartidaDisponivel()
    },[]);

    return (
        <>
            <div className="centered">
                <h1 className='ciso_logo' data-text="Cyber Advisor" onClick={AcessarPainelAdmin}>
                    <span>Cyber Advisor</span>
                </h1><br />
                {partida && <button onClick={() => IniciarDireto()}  className="button">Iniciar</button>}        
            </div>

            <Footer  />            

        </>
    );
}

export default Jogar;