import React, { useEffect, useState } from 'react';
import { SetPage, VerifyPage, database } from '../../data/firebase';
import { ref, update, onValue } from "firebase/database"
import {useNavigate} from 'react-router-dom';

import './style.css'

export const Situacao = () => {

    let userAdmin = localStorage.getItem('admin');
    let pagina = '/situacao';

    const navigate = useNavigate();

    const Continuar = () => {
        SetPage('/pergunta')
    }

    const [situacao, setSituacao] = useState({
        numero : 0,
        contexto : '',
        videoURL : '',
        imagem: ''
    })

    const GetSituacao = () => {

        let idPartida = localStorage.getItem('idPartida')
        let questaoAtual = 0;

        const dbRef = ref(database, `/partidas/${idPartida}`);
        onValue(dbRef, (snapshot) => {        
            let data = snapshot.val();
            if(parseInt(data.questaoAtual) === 0){
                update(ref(database, `/partidas/${idPartida}`), {questaoAtual: 1})
                questaoAtual = 1;
            }else{
                questaoAtual = data.questaoAtual;
            }     
            
        })

        const dbRefQuestoes = ref(database, '/questoes');

        onValue(dbRefQuestoes, (snapshot) => {
            
            let totalQuestoes = 0;

            snapshot.forEach(childSnapshot => {
                let data = childSnapshot.val();
                if(parseInt(data.ordem) === parseInt(questaoAtual)){
                    setSituacao({
                        numero : data.ordem,
                        contexto : data.contexto,
                        videoURL : data.videoURL,
                        imagem : data?.imagem
                    })
                    localStorage.setItem('contextoAtual', data.contexto);
                    localStorage.setItem('questaoAtual', data.ordem);
                }
                totalQuestoes++;                
            })
            localStorage.setItem('totalQuestoes', totalQuestoes);
        })
    }

    useEffect(()=>{
        VerifyPage(pagina, navigate);
        GetSituacao()
        localStorage.removeItem('respondido')
    },[])

    return (
        <>
            <div className='container'>
                <br />
                <div className='situacao card glass'>
                    <h2>Evento #{situacao.numero}</h2>
                    <p className='situacao_flex line_break'>
                        <span dangerouslySetInnerHTML={{__html: situacao.contexto}} ></span>
                        {situacao.imagem && <><br /><br /><img src={situacao.imagem} alt='' /></>} 
                    </p>
                    <p className='video_embed'>
                        {situacao.videoURL &&
                            <iframe width="600" height="340" title='incorporado'
                                src={situacao.videoURL}>
                            </iframe>
                        }                        
                    </p>
                    {userAdmin && <button onClick={() => Continuar()} className='button'>Continuar</button>}

                </div>
            </div>
        </>
    );
}

export default Situacao;