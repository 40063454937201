import React, {useEffect, useState} from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseConfig } from '../../../data/firebase';
import {useNavigate} from 'react-router-dom';

import './style.css'

export const Login = () => {
    
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [msgErro, setMsgErro] = useState('')

    const navigate = useNavigate();

    initializeApp(firebaseConfig);


    useEffect(() => {
      LogarComEnter()
    },[senha])

    const LogarComEnter = (e) => {
      document.addEventListener("keydown", function(event) {
        if (event.key === "Enter") {
          Logar(e);
        }
      });
    }

    const Logar = (e) =>{

        e.preventDefault();

        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, senha)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            localStorage.setItem('admin', JSON.stringify(user))
            console.log(user);
            navigate('/admin/partida')
            // ...
          })
          .catch(() => {
            setMsgErro('Usuário e senha inválidos!')
          });
    }

    return (
        <div className="container form_login">
            <form onSubmit={(e) => Logar(e)}>
              <div className='card glass'>
                  <h2>Login</h2>
                  <label>Email</label>
                  <input 
                      type="text" 
                      value={email} 
                      onChange={(e) => setEmail(e.target.value)} 
                      className="glass input_glass login_inputs"
                      placeholder='Digite seu email...' 
                  />
                  <label>Senha</label>
                  <input 
                      type="password" 
                      value={senha} 
                      onChange={(e) => setSenha(e.target.value)} 
                      className="glass input_glass login_inputs"
                      placeholder='Digite sua senha...' 
                  />
                  <span className='login_erro'>{msgErro}</span>
                  <button type='submit' onClick={(e) => Logar(e)} className='button login_inputs'>Entrar</button>
              </div>     
            </form>
        </div> 
    );
}

export default Login;