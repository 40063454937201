import { onValue } from 'firebase/database';
import React, {useEffect, useState} from 'react';

import {ref} from "firebase/database"
import { SendData, database, DeleteData, UpdateData } from '../../../data/firebase';

import './style.css';
import AdminMenu from '../../../components/Admin/Menu';

export const Questoes = () => { 

    const [todos, setTodos] = useState([]);
    const [idEdit, setIdEdit] = useState('');
    const [questao, setQuestao] = useState({
        contexto : "",
        videoURL : "",
        tempo : 60,
        ordem: 0,
        alternativa_a_texto  : "",
        alternativa_a_pontos : 10,
        alternativa_a_branch : 0,
        alternativa_b_texto  : "",
        alternativa_b_pontos : 10,
        alternativa_b_branch : 0,
        alternativa_c_texto  : "",
        alternativa_c_pontos : 10,
        alternativa_c_branch : 0,
        alternativa_d_texto  : "",
        alternativa_d_pontos : 10,
        alternativa_d_branch : 0,
        dificuldade : "Média",
        modoDeJogo  : "Padrão",
        feedback  : "",
    });

    const handleChange = (event) => {
        setQuestao({ ...questao, [event.target.name]: event.target.value });
    };


    const CadastrarQuestao = (e) =>{
        e.preventDefault();

        let endpoint = 'questoes';

        if(idEdit !== ''){
            endpoint = `questoes/${idEdit}`
            UpdateData(endpoint, questao);
        }else{
            SendData(endpoint, questao);
        }

        setIdEdit('')
        setQuestao({
            contexto : "",
            videoURL : "",
            tempo : 60,
            ordem: "0",
            alternativa_a_texto  : "",
            alternativa_a_pontos : 10,
            alternativa_a_branch : 0,
            alternativa_b_texto  : "",
            alternativa_b_pontos : 10,
            alternativa_b_branch : 0,
            alternativa_c_texto  : "",
            alternativa_c_pontos : 10,
            alternativa_c_branch : 0,
            alternativa_d_texto  : "",
            alternativa_d_pontos : 10,
            alternativa_d_branch : 0,
            dificuldade : "Média",
            modoDeJogo  : "Padrão",
            feedback  : "",
        })
    }

    const Listar = () => {
        const dbRef = ref(database, '/questoes');
        onValue(dbRef, (snapshot) => {
            let records = []
            snapshot.forEach(childSnapshot => {
                let keyName = childSnapshot.key;
                let data = childSnapshot.val();
                records.push({"key": keyName, "data": data})
            })
            
            setTodos(records)
        })
    }

    const Excluir = (id) => {
        if(window.confirm('Deseja realmente excluir?')){
            DeleteData('questoes', id)
        }
    }

    const Editar = (item) => {
        setIdEdit(item.key)
        setQuestao({
            contexto : item.data.contexto,
            videoURL : item.data.videoURL,
            tempo : item.data.tempo,
            ordem: item.data.ordem,
            alternativa_a_texto  : item.data.alternativa_a_texto,
            alternativa_a_pontos : 10,
            alternativa_a_branch : 0,
            alternativa_b_texto  : item.data.alternativa_b_texto,
            alternativa_b_pontos : 10,
            alternativa_b_branch : 0,
            alternativa_c_texto  : item.data.alternativa_c_texto,
            alternativa_c_pontos : 10,
            alternativa_c_branch : 0,
            alternativa_d_texto  : item.data.alternativa_d_texto,
            alternativa_d_pontos : 10,
            alternativa_d_branch : 0,
            dificuldade : "Média",
            modoDeJogo  : "Padrão",
            feedback  : item.data.feedback,
        })
        document.querySelector(".questoes").scrollIntoView();    
    }

    useEffect(() => {

        Listar()
        setIdEdit('')
        document.querySelector('.logo_senai').style.display = "none";
        document.querySelector('#root').style.display = "block";
        document.querySelector('body').style.height = "auto";

    },[]);

    return (

        <div className="container">
            <div className='card glass questoes'>
                <AdminMenu pagina="questoes" />
                <h2>Questões</h2>

                <label>Contexto</label>
                <input 
                    type="text" 
                    name="contexto" 
                    value={questao.contexto} 
                    onChange={(e) => handleChange(e) }
                    className="glass input_glass"
                />
                
                <label>URL do Youtube</label>
                <input 
                    type="text" 
                    name="videoURL" 
                    value={questao.videoURL} 
                    onChange={(e) => handleChange(e) }
                    className="glass input_glass"
                />

                <label>Tempo em Segundos</label>
                <input 
                    type="number" 
                    name="tempo" 
                    value={questao.tempo} 
                    onChange={(e) => handleChange(e) }
                    className="glass input_glass"
                />

                <label>Ordem da Questão</label>
                <input 
                    type="number" 
                    name="ordem" 
                    value={questao.ordem} 
                    onChange={(e) => handleChange(e) }
                    className="glass input_glass"
                />

                <label>Alternativa A</label>
                <input 
                    type="text" 
                    name="alternativa_a_texto" 
                    value={questao.alternativa_a_texto} 
                    onChange={(e) => handleChange(e) }
                    className="glass input_glass"
                />
                <label>Alternativa B</label>
                <input 
                    type="text" 
                    name="alternativa_b_texto" 
                    value={questao.alternativa_b_texto} 
                    onChange={(e) => handleChange(e) }
                    className="glass input_glass"
                />
                <label>Alternativa C</label>
                <input 
                    type="text" 
                    name="alternativa_c_texto" 
                    value={questao.alternativa_c_texto} 
                    onChange={(e) => handleChange(e) }
                    className="glass input_glass"
                />
                <label>Alternativa D</label>
                <input 
                    type="text" 
                    name="alternativa_d_texto" 
                    value={questao.alternativa_d_texto} 
                    onChange={(e) => handleChange(e) }
                    className="glass input_glass"
                />
                <label>Feedback da Questão</label>
                <input 
                    type="text" 
                    name="feedback" 
                    value={questao.feedback} 
                    onChange={(e) => handleChange(e) }
                    className="glass input_glass"
                />
                <button className='button'onClick={(e) => CadastrarQuestao(e)} >
                    {idEdit !== '' ? 'Alterar' : 'Cadastrar'}
                </button>
            </div>

            <div className='card glass questoes scroll'>
                
                <div className='lista'>

                    {todos.map((item, key) => 
                            <div className='item glass' key={key}>                        
                                <h4>Evento #{item.data.ordem}</h4>
                                <p>{item.data.contexto}</p><br />
                                <h4>Qual seria a ação?</h4>
                                <p>A - {item.data.alternativa_a_texto}</p>
                                <p>B - {item.data.alternativa_b_texto}</p>
                                <p>C - {item.data.alternativa_c_texto}</p>
                                <p>D - {item.data.alternativa_d_texto}</p><br />
                                
                                <h4>Feedback:</h4>
                                <p>{item.data.feedback}</p><br />

                                <button className='glass btn_action btn_warning' onClick={() => Editar(item)}>Editar</button>
                                <button className='glass btn_action btn_danger' onClick={() => Excluir(item.key)}>Excluir</button>
                            </div>
                        
                    )}


                </div>

            </div>

        </div>       

    );
}

export default Questoes;