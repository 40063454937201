import React from 'react';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import './style.css'

const AdminMenu = (props) => {

    const navigate = useNavigate();

    const Sair = (e) => {
        e.preventDefault();
        localStorage.removeItem('admin')
        localStorage.removeItem('totalQuestoes')
        navigate('../../admin/login')
    }

    return (
        <nav>
            <ul>
                <li>
                    <Link to="/admin/partida" className={props.pagina == "partida" ? "button glass btn_menu active" : "button glass btn_menu "}>Evento</Link>
                    {/* <Link to="/admin/questoes" className={props.pagina == "questoes" ? "button glass btn_menu active" : "button glass btn_menu "}>Questões</Link> */}
                    <Link to="#" onClick={(e) => Sair(e)} className="button glass btn_menu ">Sair</Link>
                </li>
            </ul>
        </nav>
    );
}

export default AdminMenu;