import React, { useEffect, useState } from 'react';

import { database, SetPage, VerifyPage } from '../../data/firebase';
import { ref, update, onValue} from "firebase/database"
import {useNavigate} from 'react-router-dom';

import './style.css'

export const Situacao = () => {

    let idPartida = localStorage.getItem('idPartida');
    let userAdmin = localStorage.getItem('admin');

    const navigate = useNavigate();

    const [reflexaoTxt,setReflexaoTxt] = useState('');

    const CarregarReflexao = () => {
        
        
        const dbRef = ref(database, '/reflexao');
        onValue(dbRef, (snapshot) => {            
            let data = snapshot.val();
            console.log(data);
            setReflexaoTxt(data.texto)
        })
    }

    const FinalizarPartida = () => {
        update(ref(database, `/partidas/${idPartida}`), {dataFinal: Date.now()})        
        SetPage('/jogar')
        localStorage.removeItem('idPartida')
    }

    let pagina = '/resultados';

    useEffect(() => {
        VerifyPage(pagina, navigate);
        GetAssertividade();
        GetAssertividadeGeral();
        CarregarReflexao();
    }, [])

    // Melhores e piores questões da rodada

    // Assertividade
    const [assertividade, setAssertividade] = useState('')
    const GetAssertividade = () =>{

        let TotalPontos = 0;
        let Registros   = 0;

        let idPartida = localStorage.getItem('idPartida')
        let idJogador = localStorage.getItem('idJogador')

        if(idJogador !== null){
            const dbRef = ref(database, `/partidas/${idPartida}/jogadores/${idJogador}/questoes`);
            onValue(dbRef, (snapshot) => {
                let data = snapshot.val();
                data.forEach(questao => {
                    TotalPontos += questao.pontos;
                    Registros = Registros+1;
                });
                let percentual = TotalPontos/Registros;
                setAssertividade(percentual.toFixed(0));
            })
        }
    }

    // Assertividade Geral
    const [assertividadeGeral, setAssertividadeGeral] = useState('')
    const GetAssertividadeGeral = () =>{

        let TotalPontos = 0;
        let Registros   = 0;

        let idPartida = localStorage.getItem('idPartida')

        // const dbRef = ref(database, `/partidas/${idPartida}/jogadores`);
        // onValue(dbRef, (snapshot) => {
        //     let data = [snapshot.val()];
        //     data.forEach((item) => {                
        //         let jogador = item[Object.keys(item)[0]];
        //         jogador?.questoes.forEach(pontos => {
        //             TotalPontos += pontos?.pontos
        //             Registros    = Registros + 1;
        //         })
        //     })
        // })
        setTimeout(() => {
            let percentual = TotalPontos/Registros;
            setAssertividadeGeral(percentual.toFixed(0));
        }, 2000);
    }

    return (
        <>
            <div className='container'>
                <div className='card glass'>
                    <h2>Resultados</h2>
                    {!userAdmin && <h3>Assertividade: {assertividade && `${assertividade}%`}</h3>}
                    <div className='card glass'>
                        <h3>Reflexões sobre os eventos ocorridos : </h3>
                        <p>{reflexaoTxt}</p>
                    </div>
                    {userAdmin && <button onClick={() => FinalizarPartida()} className='button'>Finalizar</button>}
                </div>
            </div>
        </>
    );
}

export default Situacao;