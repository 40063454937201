import React, { useEffect, useState } from 'react';
import { SetPage, VerifyPage, database } from '../../data/firebase';
import { onValue, ref } from "firebase/database";
import {useNavigate} from 'react-router-dom';

import './style.css';

export const ObjetivoGeral = () => {

    const navigate = useNavigate();
    let userAdmin = localStorage.getItem('admin');
    let pagina = '/objetivogeral'

    const [contextoTxt,setContextoTxt] = useState('');
    const [contextoLink,setContextoLink] = useState('');
    const [contextoImg,setContextoImg] = useState('');

    const Continuar = () => {
        SetPage('/situacao')
    }

    const CarregarContexto = () => {
        
        
        const dbRef = ref(database, '/contexto');
        onValue(dbRef, (snapshot) => {
            
            let data = snapshot.val();

            setContextoTxt(data.texto)
            if(data.link){
                setContextoLink(data.link)
            }
            if(data.imagem){
                setContextoImg(data.imagem) 
            }
        })
    }

    useEffect(()=>{
        SetPage('/objetivogeral')
        VerifyPage(pagina, navigate)
        CarregarContexto()
    },[])

    return (
        <>
            <div className='container'>

                <div className='card glass objetivo'>
                    <h2>Gestão de Crise Cibernética</h2>
                    <h3>CONTEXTO GERAL:</h3><br />
                    <div className='contexto_flex'>
                        <p>{contextoTxt}</p>                        
                    </div>
                    <br />
                    <p>
                        {contextoLink && <a href={contextoLink}  target='_blank'>Acessar Site</a>}
                        {contextoImg && <img src={contextoImg} width="200" />}
                    </p>
                </div>


                {userAdmin && <button onClick={() => Continuar()} className='button'>Continuar</button>}

            </div>
        </>
    );
}

export default ObjetivoGeral;