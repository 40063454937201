import React, { useRef, useEffect } from 'react';
import video from '../../assets/video.mp4';


const Video = () => {

    const videoRef = useRef(null);

    useEffect(() => {
        videoRef.current.play();
    },[])

    return (
        <video ref={videoRef} autoPlay muted loop id="videobackground">
            <source src={video} type="video/mp4" />
        </video>
    );
}

export default Video;