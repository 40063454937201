import React from 'react';

import logo from '../../assets/logo-senai.png'
import './style.css'

const Footer = () => {
    return (
        <footer>
            <img src={logo} className="logo_senai" alt="Logo do SENAI" />
        </footer>
    );
}

export default Footer;