import React, { useEffect, useState } from 'react';
import { SetPage, VerifyPage, database } from '../../data/firebase';
import { ref, update, onValue } from "firebase/database"
import {useNavigate} from 'react-router-dom';

import './style.css'

export const Feedback = () => {

    let userAdmin = localStorage.getItem('admin');
    let idPartida = localStorage.getItem('idPartida');
    let questaoAtual = localStorage.getItem('questaoAtual')
    let contexto = localStorage.getItem('contextoAtual');

    const navigate = useNavigate();

    let pagina = '/feedback';

    const [a, setA] = useState(0);
    const [b, setB] = useState(0);
    const [c, setC] = useState(0);
    const [d, setD] = useState(0);
    const [total, settotal] = useState(a+b+c+d);


    const Continuar = () => {

        let PerguntaAtual = localStorage.getItem('questaoAtual')
        let proxima = parseInt(PerguntaAtual) + 1;
        localStorage.setItem('questaoAtual', proxima)    
        update(ref(database, `/partidas/${idPartida}`), {questaoAtual: proxima})


        let totalQuestoes = localStorage.getItem('totalQuestoes')
        if(proxima > parseInt(totalQuestoes)){
            SetPage('/resultados')
        }else{
            SetPage('/situacao')
        }
    }

    const [corretas, setCorretas] = useState([])

    const MostrarCorretas = () => {       

        const dbRefQuestoes = ref(database, '/questoes');
        let resCorretas = [];
        onValue(dbRefQuestoes, (snapshot) => {
            snapshot.forEach(childSnapshot => {
                let data = childSnapshot.val();
                if(parseInt(data.alternativa_a_pontos) === 100 && parseInt(data.ordem) === parseInt(questaoAtual)){
                    resCorretas.push({alternativa: 'A', texto: data.alternativa_a_texto, feedback: data.feedback })
                } 
                if(parseInt(data.alternativa_b_pontos) === 100 && parseInt(data.ordem) === parseInt(questaoAtual)){
                    resCorretas.push({alternativa: 'B', texto: data.alternativa_b_texto, feedback: data.feedback })
                }
                if(parseInt(data.alternativa_c_pontos) === 100 && parseInt(data.ordem) === parseInt(questaoAtual)){
                    resCorretas.push({alternativa: 'C', texto: data.alternativa_c_texto, feedback: data.feedback })
                } 
                if(parseInt(data.alternativa_d_pontos) === 100 && parseInt(data.ordem) === parseInt(questaoAtual)){
                    resCorretas.push({alternativa: 'D', texto: data.alternativa_d_texto, feedback: data.feedback })
                }    
            })
            setCorretas(resCorretas);
        })
    }

    const getQtdRespostas =  () => {

        let questaoAtual = localStorage.getItem('questaoAtual');

        const dbRef = ref(database, `/partidas/${idPartida}/jogadores`);

        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;

        if(questaoAtual == null){
            questaoAtual = 1
        }

        onValue(dbRef, (snapshot) => {
            snapshot.forEach(childSnapshot => {
                let data1 = childSnapshot.val();

                if(data1.questoes){
                    if(data1.questoes[questaoAtual]?.resposta === 'a'){
                        a = a+1;
                    }
                    if(data1.questoes[questaoAtual]?.resposta === 'b'){
                        b = b+1;
                    }
                    if(data1.questoes[questaoAtual]?.resposta === 'c'){
                        c = c+1;
                    }
                    if(data1.questoes[questaoAtual]?.resposta === 'd'){
                        d = d+1;
                    }
                }
                
            })
            
            setA(a);
            setB(b);
            setC(c);
            setD(d);
        })        
        
        
    }
    
    useEffect(()=>{        
        VerifyPage(pagina, navigate);
        getQtdRespostas();
        MostrarCorretas();
    },[])
    
    useEffect(() => {
        settotal(a+b+c+d);

        document.querySelector('.res1').style.height = `${getPercentual(a, total)}%`;
        document.querySelector('.res2').style.height = `${getPercentual(b, total)}%`;
        document.querySelector('.res3').style.height = `${getPercentual(c, total)}%`;
        document.querySelector('.res4').style.height = `${getPercentual(d, total)}%`;
        
    }, [a,b,c,d,total])

    const getPercentual = (valor, total) => {
        if(valor === 0){
            return(0);
        }
        return valor/total * 100;
    }

    return (
        <>
            <div className='container'>
                
                <div className='card glass'>

                    <h2>Feedback</h2>

                    <h3 className='acao_ideal'>AÇÕES ADEQUADAS: </h3>

                    {corretas.map((item, key) => {
                        return <p key={key} className="glass correta">{item.alternativa} - {item.texto}</p>
                    })} <br />
                    {corretas[0]?.feedback && `Justificativa: ${corretas[0]?.feedback}`}

                    <div className='resultados'>
                        <div className='res res1'>
                            <span>A ► {a}</span>
                        </div>
                        <div className='res res2'>
                            <span>B ► {b}</span>
                        </div>
                        <div className='res res3'>
                            <span>C ► {c}</span>
                        </div>
                        <div className='res res4'>
                            <span>D ► {d}</span>
                        </div>
                    </div>
                    {userAdmin && <button onClick={() => Continuar()} className='button'>Continuar</button>}
                </div>
            </div>
        </>
    );
}

export default Feedback;